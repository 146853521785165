import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AutoComplete } from '../_models/search/autocomplete.model';
import { SearchResults } from '../_models/search/search-results.model';
import { ProductSearchResults } from '../_models/search/product.search-results.model';
import { ProductFilterOption } from '../_models/catalog/product-filter.model';
import { ProductSearchRequest } from '../_models/search/product.search-request.model';
import { BlogPost } from '../_models/catalog/blog.model';

@Injectable({
  providedIn: 'root'
})

export class RepositorySearchService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  constructor(private http: HttpClient,
    private cookieService: CookieService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public search(term: string): Observable<SearchResults> {
    try {
      return new Observable((observer) => {
        this.subscriptions.push(this.http.get(this.createCompleteRoute('All', term, environment.searchEndPoint, 0, environment.pageSize), this.generateHeaderOptions(environment.searchEndPoint)).subscribe({
          next: (result: SearchResults) => {
            observer.next(result);
            observer.complete();
          },
          error: (e: HttpErrorResponse) => {
            observer.error(e?.error);
            observer.complete();
          }
        }));
      });
    }
    catch {
      console.log('Error getting product search results');
      return null;
    }
  }

  public suggestBlogPost(term: string): Observable<AutoComplete[]> {
    try {
      return new Observable((observer) => {
        this.subscriptions.push(this.http.get(this.createCompleteRoute('BlogTerms', term, environment.searchEndPoint), this.generateHeaderOptions(environment.searchEndPoint)).subscribe({
          next: (result: AutoComplete[]) => {
            observer.next(result);
            observer.complete();
          },
          error: (e: HttpErrorResponse) => {
            observer.error(e?.error);
            observer.complete();
          }
        }));
      });
    }
    catch {
      console.log('Error getting blog suggest results');
      return null;
    }
  }

  public searchBlogPosts(term: string): Observable<BlogPost[]> {
    try {
      return new Observable((observer) => {
        this.subscriptions.push(this.http.get(this.createCompleteRoute('Blog', term, environment.searchEndPoint), this.generateHeaderOptions(environment.searchEndPoint)).subscribe({
          next: (result: BlogPost[]) => {
            observer.next(result);
            observer.complete();
          },
          error: (e: HttpErrorResponse) => {
            observer.error(e?.error);
            observer.complete();
          }
        }));
      });
    }
    catch {
      console.log('Error getting blog search results');
      return null;
    }
  }

  public suggestProduct(term: string): Observable<AutoComplete[]> {
    try {
      return new Observable((observer) => {
        this.subscriptions.push(this.http.get(this.createCompleteRoute('SuggestProduct', term, environment.searchEndPoint), this.generateHeaderOptions(environment.searchEndPoint)).subscribe({
          next: (result: AutoComplete[]) => {
            observer.next(result);
            observer.complete();
          },
          error: (e: HttpErrorResponse) => {
            observer.error(e?.error);
            observer.complete();
          }
        }));
      });
    }
    catch {
      console.log('Error getting product suggest results');
      return null;
    }
  }

  public suggestProductTerms(term: string): Observable<AutoComplete[]> {
    try {
      return new Observable((observer) => {
        this.subscriptions.push(this.http.get(this.createCompleteRoute('ProductTerms', term, environment.searchEndPoint), this.generateHeaderOptions(environment.searchEndPoint)).subscribe({
          next: (result: AutoComplete[]) => {
            observer.next(result);
            observer.complete();
          },
          error: (e: HttpErrorResponse) => {
            observer.error(e?.error);
            observer.complete();
          }
        }));
      });
    }
    catch {
      console.log('Error getting product term results');
      return null;
    }
  }

  public searchProducts(term: string): Observable<SearchResults> {
    try {
      return new Observable((observer) => {
        this.subscriptions.push(this.http.get(this.createCompleteRoute('Product', term, environment.searchEndPoint), this.generateHeaderOptions(environment.searchEndPoint)).subscribe({
          next: (result: SearchResults) => {
            observer.next(result);
            observer.complete();
          },
          error: (e: HttpErrorResponse) => {
            observer.error(e?.error);
            observer.complete();
          }
        }));
      });
    }
    catch {
      console.log('Error getting product search results');
      return null;
    }
  }

  public searchProductsPaged(term: string, pageIndex: number, pageSize: number, filters: ProductFilterOption[]): Observable<ProductSearchResults> {
    try {
      return new Observable((observer) => {
        const request = new ProductSearchRequest();
        request.term = term;
        request.pageIndex = pageIndex;
        request.pageSize = pageSize;
        if (filters) {
          request.filters = filters;
        } else {
          request.filters = [];
        }
        this.subscriptions.push(this.http.post(`${environment.searchEndPoint}/Search/ElasticSearch/Product/${environment.storeId}`, request, this.generateHeaderOptions(environment.searchEndPoint)).subscribe({
          next: (result: ProductSearchResults) => {
            observer.next(result);
            observer.complete();
          },
          error: (e: HttpErrorResponse) => {
            observer.error(e?.error);
            observer.complete();
          }
        }));
      });
    }
    catch {
      console.log('Error getting product search results');
      return null;
    }
  }

  private createCompleteRoute = (routeEnd: string, qualifier: string, envAddress: string, pageIndex?: number, pageSize?: number) => {
    if (pageSize) {
      return `${envAddress}/Search/ElasticSearch/${routeEnd}/${environment.storeId}/${encodeURIComponent(qualifier)}/${pageIndex}/${pageSize}`;
    }
    return `${envAddress}/Search/ElasticSearch/${routeEnd}/${environment.storeId}/${encodeURIComponent(qualifier)}`;
  }

  private generateHeaderOptions = (endPoint: string) => {
    if (localStorage.getItem('token') != null) {
      return {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': endPoint,
          'Access-Control-Allow-Methods': 'GET',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
          'omnial': this.omnialHeader()
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': endPoint,
          'Access-Control-Allow-Methods': 'GET',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
          'omnial': this.omnialHeader()
        })
      };
    }
  }

  public omnialHeader(): string {
    let customerGuid = this.getCustomerGuid();
    if (!customerGuid) {
      customerGuid = null;
    }
    const omnialValues = {
      'cacheWorker': false,
      'storeId': environment.storeId,
      'customerGuid': customerGuid,
      'pageSize': environment.pageSize,
      'topLevelCatId': environment.rootCatId,
      'includeManufacturers': environment.manufacturers,
      'currencyCode': environment.currencyCode
    };
    return JSON.stringify(omnialValues);
  }

  public getCustomerGuid(): string {
    try {
      if (this.cookieService.check(environment.customerCookieKey)) {
        localStorage.setItem(environment.customerCookieKey, this.cookieService.get(environment.customerCookieKey));
        return this.cookieService.get(environment.customerCookieKey);
      } else {
        setTimeout(() => {
          if (this.cookieService.check(environment.customerCookieKey)) {
            localStorage.setItem(environment.customerCookieKey, this.cookieService.get(environment.customerCookieKey));
          }
        }, 500);
        return localStorage.getItem(environment.customerCookieKey);
      }
    } catch (e) {
      return '';
    }
  }
}
